import React, { useEffect, useState } from "react"
import "./layout.scss"
import HeaderComponent from "./pages/shared/header"
import FeedbackOverlayComponent from "./overlays/feedback"
import FeedbackOverlayService from "../services/feedback-overlay-service"
import SubscribeOverlayService from "../services/subscribe-overlay-service"
import SubscriberOverlayComponent from "./overlays/subscribe"
import PageService from "../services/page-service"
import FooterComponent from "./pages/shared/footer"
import { PageContext } from "./page-context"
import { LangContext } from "./lang-context"
import FooterSimpleComponent from "./pages/shared/footer/simple"
import { openOverlay } from "../utils/overlay"
import { navigate } from "gatsby-link"

const Layout = ({
                  /* TODO: infobar: hideInfoBar, */
                  className,
                  children,
                  forceFixedHeader,
                  headerColor,
                  page,
                  pageClassName,
                  lang,
                }) => {

  const [modalOpen, setModalOpen] = useState(false)
  const [subscribeOpen, setSubscribeOpen] = useState(false)

  useEffect(() => {
    FeedbackOverlayService.addListener(setModalOpen)
    return () => FeedbackOverlayService.removeListener(setModalOpen)
  })

  useEffect(() => {
    SubscribeOverlayService.addListener(setSubscribeOpen)
    return () => SubscribeOverlayService.removeListener(subscribeOpen)
  })

  useEffect(() => {
    const feedback = getParam("feedback")
    if (feedback) {
      openOverlay(page, "external", "link")
      navigate(removeParam("feedback"), { replace: true })
    }
  })

  useEffect(() => {
    PageService.onPageOpen(page)
  }, [page])

  const hasInfoBar = false // TODO: infobar: lang !== "en" && !hideInfoBar

  return <>
    <PageContext.Provider value={page}>
      <LangContext.Provider value={lang || "ru"}>
        <main className={className}>{children}</main>
        <FeedbackOverlayComponent show={modalOpen}/>
        <SubscriberOverlayComponent show={subscribeOpen}/>
      </LangContext.Provider>
    </PageContext.Provider>
  </>
}

function getParam(parameter) {
  const match = RegExp("[?&]" + parameter + "=([^&]*)").exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}

function removeParam(parameter) {
  let url = window.location.search
  const urlParts = url.split("?")

  if (urlParts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + "="
    const pars = urlParts[1].split(/[&;]/g)

    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    url = urlParts[0] + (pars.length > 0 ? "?" + pars.join("&") : "")
    return `${window.location.pathname}${url}`
  } else {
    return url
  }
}

export default Layout
