import React from "react"

import styles from "./info.module.scss"
import SubscribeOverlayService from "../../../../../services/subscribe-overlay-service"

const InfoComponent = () => (
  <div className={styles.info}>
    <div className={`container ${styles.container}`}>
      <section className={styles.fisrt}>
        <div className={styles.logo}>
          <img src={require("../../../../../images/icons/info-logo-paraplan.svg")} alt={"Холмонт логотип"} />
        </div>
        <h2 className={styles.title}>Вас приветствует Параплан CRM!</h2>
        <p className={styles.text}>
          Учет базы клиентов, лидов. <br />
          Личный кабинет клиента и сотрудника. Ведение отчетности, выплата з\п. <br />
          СМС-рассылки, IP-телефония, автоуведомления, мессенджеры, расписание для сайта и другие интеграции.
        </p>
        <div className={styles.subtext}>Легко начните уже сейчас!</div>
        <a href="https://paraplancrm.ru/" target="_blank" className={styles.cta}>Получить доступ на 10 дней</a>
      </section>
      <section className={styles.second}>
        <h3 className={styles.subtitle}>Как выглядит система?</h3>
        <p className={styles.text}>Убедитесь сами - все просто!</p>
        <a href="https://youtu.be/v7A9yZXTqo4" target="_blank" className={styles.green}>Посмотреть обзор</a>
      </section>
      <section className={styles.third}>
        <h3 className={styles.subtitle}>У вас детский центр?</h3>
        <p className={styles.text}>Держите чек-лист по увеличению прибыли центра всего за <b>15 шагов!</b> </p>
        <button className={styles.blue} onClick={() => SubscribeOverlayService.open()}>Скачать чек-лист</button>
      </section>
      <section className={styles.fourth}>
        <p className={styles.text}>Проведем индивидуальную презентацию и ответим на все вопросы! Пишите!</p>
        <ul className={styles.list}>
          <li><a href="https://t.me/paraplancrm" target="_blank"><img src={require("../../../../../images/icons/info-social-tg.svg")} alt={"Холмонт логотип"} /></a></li>
          <li><a href="https://wa.me/79991709714" target="_blank"><img src={require("../../../../../images/icons/info-social-ws.svg")} alt={"Холмонт логотип"} /></a></li>
        </ul>
      </section>
      {/*<section className={styles.fifth}>
        <p className={styles.text}>Самые актуальные новости в наших группах</p>
        <ul className={styles.list}>
          <li><a href="https://vk.com/paraplancrm" target="_blank"><img src={require("../../../../../images/icons/info-social-vk.svg")} alt={"Холмонт логотип"} /></a></li>
          <li><a href="https://www.facebook.com/paraplancrm" target="_blank"><img src={require("../../../../../images/icons/info-social-fb.svg")} alt={"Холмонт логотип"} /></a></li>
          <li><a href="https://instagram.com/paraplan_crm_" target="_blank"><img src={require("../../../../../images/icons/info-social-inst.svg")} alt={"Холмонт логотип"} /></a></li>
        </ul>
      </section>*/}
      <section className={styles.fifth}>
        <p className={styles.text}>Делимся новостями о системе и полезными знаниями в Telegram</p>
        <a href="https://t.me/ParaplanCRMnews" target="_blank" className={styles.orange}>Подписаться на канал</a>
      </section>
    </div>
  </div>
)

export default InfoComponent
