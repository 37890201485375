import React from "react"
import Layout from '../../components/layout-landing-info'
import SEO from "../../components/seo"
import InfoComponent from "../../components/pages/main/landing/info"

const InfoPage = (props) => (
  <Layout>
    <SEO
      title="Вас приветствует Параплан CRM!"
      description="Учет базы клиентов, лидов.  Личный кабинет клиента и сотрудника. Ведение отчетности, выплата з\п. СМС-рассылки, IP-телефония, автоуведомления, мессенджеры, расписание для сайта и другие интеграции."
      keywords={[`crm-система`, `учет клиентов`, `ведение отчетности`, `отчетность`, `выплата з\п`, `СМС-рассылки`, `IP-телефония`, `автоуведомления`, `мессенджеры`]}
      url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <InfoComponent></InfoComponent>
  </Layout>
)

export default InfoPage